import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;
export class UsersService {
    constructor(http) {
        this.http = http;
    }
    getUsers() {
        const endPoint = "/api/admin/";
        return this.http.get(apiURL + endPoint, { headers: headers });
    }
    getByRole(role, userGroup) {
        const endPoint = `/api/admin/byrole/${role}/${userGroup ? userGroup : ""}`;
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getExecutiveProjectUsers() {
        const endPoint = '/api/Admin/ProjectUsers';
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getUsersByFilters(request) {
        const endPoint = "/api/admin/search";
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    getUserById(id) {
        const endPoint = `/api/admin/${id}`;
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updateUser(request) {
        const endPoint = `/api/admin/`;
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    createUser(request) {
        const endPoint = `/api/admin/`;
        return this.http.put(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    createDoctor(request) {
        const endPoint = `/api/admin/doctor`;
        return this.http.put(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    deleteUser(id) {
        const endPoint = `/api/admin/${id}`;
        return this.http.delete(`${apiURL}${endPoint}`, { headers: headers });
    }
    getDoctorToApprove() {
        let endPoint = "/api/admin/doctor/toapprove";
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getAddressById(id, addressType) {
        let endPoint = `/api/admin/addresses/${id}/${addressType}`;
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getOptions(optionName) {
        let endPoint = `/api/options/labels/${optionName}`;
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    confirmEmail(token) {
        let request = {
            "resetToken": token
        };
        let endPoint = `/api/admin/confirmregistration`;
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    updateUserPhoto(id, name) {
        let endPoint = `/api/admin/updatephoto`;
        let request = {
            "fileName": name,
            "idAdmin": id
        };
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    getByGroup(userGroup, active) {
        let endPoint = `/api/admingroup/${userGroup}`;
        if (active) {
            endPoint = `/api/admingroup/${userGroup}/true`;
        }
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getAllInternalUsers(active) {
        let endPoint = `/api/admingroup/all`;
        if (active) {
            endPoint = `/api/admingroup/all/true`;
        }
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updateUserAsUserGroup(request, idUser) {
        let endPoint = `/api/admingroup/`;
        if (idUser) {
            request.idUser = idUser;
            return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
        }
        return this.http.put(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    deleteUserGroupUser(idUser) {
        let endPoint = `/api/admingroup/${idUser}`;
        return this.http.delete(`${apiURL}${endPoint}`, { headers: headers });
    }
    getSettings() {
        let endPoint = `/api/setting/`;
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getClinicPreferences(idDoctor) {
        let endPoint = `/api/admin/doctor/clinicpref/` + idDoctor;
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    saveSettings(request) {
        let endPoint = `/api/setting/`;
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    saveClinicPreferences(request) {
        let endPoint = `/api/admin/doctor/clinicpref/`;
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient)); }, token: UsersService, providedIn: "root" });
