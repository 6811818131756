import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';

const apiURL = environment.apiUrl
const headers = httpHeaders.headers

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }


  public getUsers() {
    const endPoint = "/api/admin/";
    return this.http.get(apiURL + endPoint, { headers: headers });
  }

  public getByRole(role: string, userGroup?: string) {
    const endPoint = `/api/admin/byrole/${role}/${userGroup ? userGroup : ""}`;
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public getExecutiveProjectUsers() {
    const endPoint = '/api/Admin/ProjectUsers';
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public getUsersByFilters(request:any) {
    const endPoint = "/api/admin/search";
    return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public getUserById(id: any) {
    const endPoint = `/api/admin/${id}`;
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public updateUser(request: any) {
    const endPoint = `/api/admin/`;
    return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public createUser(request: any) {
    const endPoint = `/api/admin/`;
    return this.http.put(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public createDoctor(request: any) {
    const endPoint = `/api/admin/doctor`;
    return this.http.put(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public deleteUser(id: any) {
    const endPoint = `/api/admin/${id}`;
    return this.http.delete(`${apiURL}${endPoint}`, { headers: headers });
  }

  public getDoctorToApprove() {
    let endPoint = "/api/admin/doctor/toapprove";
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public getAddressById(id, addressType) {
    let endPoint = `/api/admin/addresses/${id}/${addressType}`;
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public getOptions(optionName) {
    let endPoint = `/api/options/labels/${optionName}`;
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public confirmEmail(token: string) {
    let request = {
      "resetToken": token
    }
    let endPoint = `/api/admin/confirmregistration`;
    return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public updateUserPhoto(id, name) {
    let endPoint = `/api/admin/updatephoto`;
    let request = {
      "fileName": name,
      "idAdmin": id
    }
    return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  public getByGroup(userGroup: string, active?:boolean) {
    let endPoint = `/api/admingroup/${userGroup}`;

    if(active){
      endPoint = `/api/admingroup/${userGroup}/true`;
    }

    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public getAllInternalUsers(active?:boolean) {
    let endPoint = `/api/admingroup/all`;

    if(active){
      endPoint = `/api/admingroup/all/true`;
    }

    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  public updateUserAsUserGroup(request: any, idUser: string) {
    let endPoint = `/api/admingroup/`;

    if (idUser) {
      request.idUser = idUser;
      return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }

    return this.http.put(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  deleteUserGroupUser(idUser: any) {
    let endPoint = `/api/admingroup/${idUser}`;

    return this.http.delete(`${apiURL}${endPoint}`, { headers: headers });
  }

  getSettings(){
    let endPoint = `/api/setting/`;
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  getClinicPreferences(idDoctor:number){
    let endPoint = `/api/admin/doctor/clinicpref/` + idDoctor;
    return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
  }

  saveSettings(request){
    let endPoint = `/api/setting/`;
    return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }

  saveClinicPreferences(request){
    let endPoint = `/api/admin/doctor/clinicpref/`;
    return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
  }
}
