import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpHeaders } from '../../../../environments/httpHeaders';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { News } from '../shared/models/news';
import { Shopping } from '../shared/models/shopping';
import { Cart } from '../shared/models/cart';
import { StorageFile } from '../shared/models/file';
import { UserService } from 'src/app/modules/security/services/user.service';
import { BehaviorSubject } from 'rxjs';
import {objectToFormData} from '../../../helpers/utils';

const apiURL = environment.apiUrl;
const arcadLab = environment.arcadLab;
const headers = httpHeaders.headers;
const fileHeader = httpHeaders.fileHeaders;

@Injectable({
  providedIn: 'root'
})
export class ContentsService {

  public cart = new BehaviorSubject({
    data: [],
    total: 0,
  });

  constructor(
    private _http: HttpClient,
    private _userService: UserService
  ) {}


  getNewsContent(onlyActive: boolean = false) {
    const endPoint = '/api/news/' + onlyActive;
    return this._http.get(apiURL + endPoint, {headers: headers}).pipe(map((response: News[]) => {
      return response;
    }));
  }

  getCategories() {
    const endPoint = '/api/Options/Labels/SC';
    return this._http.get(apiURL + endPoint, {headers: headers}).pipe(map((response: []) => {
      return response;
    }));
  }

  getNewsById(id) {
    const endPoint = '/api/news/id/' + id;
    return this._http.get(apiURL + endPoint, {headers: headers}).pipe(map((response: News) => {
      return response;
    }));
  }

  getShoppingContent() {
    const endPoint = '/api/shopping/';
    return this._http.get(apiURL + endPoint, {headers: headers}).pipe(map((response: Shopping[]) => {
      return response;
    }));
  }

  getProduct(productId) {
    const endPoint = `/api/shopping/id/${productId}`;
    return this._http.get(apiURL + endPoint, {headers: headers}).pipe(map((response: Shopping) => {
      return response;
    }));
  }

  updateProductsPosition(data) {
    const endPoint = `/api/shopping/UpdateCategoryPosition`;
    return this._http.post(apiURL + endPoint, data, {headers: headers}).pipe(map((response: Shopping) => {
      return response;
    }));
  }

  deleteShoppingItem(productId) {
    const endPoint = `/api/shopping/${productId}`;
    return this._http.delete(apiURL + endPoint, {headers: headers}).pipe(map((response) => {
      return response;
    }));
  }

  createProduct(data) {
    const endPoint = '/api/shopping/';
    const formData = objectToFormData(data);
    const httpParams = new HttpHeaders();
    return this._http.post(apiURL + endPoint, formData, {headers: httpParams}).pipe(map((response) => {
      return response;
    }));
  }

  updateProduct(data) {
    const endPoint = '/api/shopping/';
    const httpParams = new HttpHeaders();
    return this._http.put(apiURL + endPoint, data, {headers: headers}).pipe(map((response) => {
      return response;
    }));
  }

  uploadProductFile(data) {
    const endPoint = '/api/shopping/uploadFile';
    const formData = objectToFormData(data);
    const httpParams = new HttpHeaders();
    return this._http.post(apiURL + endPoint, formData, {headers: httpParams}).pipe(map((response) => {
      return response;
    }));
  }

  deleteProductFile(productId, fieldName) {
    const endPoint = `/api/shopping/deleteFile/${productId}/${fieldName}`;
    return this._http.delete(apiURL + endPoint, {headers: headers}).pipe(map((response) => {
      return response;
    }));
  }

  getCartContent() {
    return this.cart;
  }

  sendOrder(data) {
    const endPoint = '/api/shopping/buy';
    return this._http.post(apiURL + endPoint, data, {headers: headers}).pipe(map((response) => {
      return response;
    }));
  }

  calculateTotal() {
    const currentCart = {...this.cart.getValue()};
    let total = 0;
    currentCart.data.forEach(item => {
      total += item.count * item.price;
    });
    return total;
  }

  addProductToCart(product) {
    const cartItem = new Cart(product);
    const currentCart = this.cart.getValue();
    const isInCart = currentCart.data.find(item => item.productId === cartItem.productId);
    if (isInCart) {
      cartItem.count += isInCart.count;
      cartItem.total = cartItem.count * cartItem.price;
      currentCart.data = [...currentCart.data.filter(item => item.productId !== cartItem.productId)];
    }
    const updatedCartData = [...currentCart.data, cartItem];
    this.cart.next({
      ...currentCart,
      data: updatedCartData,
    });
    const total = this.calculateTotal();
    this.cart.next({
      total,
      data: updatedCartData,
    });
    return this.cart;
  }

  removeProductToCart(productId) {
    const currentCart = this.cart.getValue();
    const updatedCartValue = currentCart.data.filter(item => {
      return item.productId !== productId;
    });
    this.cart.next({
      ...currentCart,
      data: updatedCartValue,
    });
    const total = this.calculateTotal();
    this.cart.next({
      total,
      data: updatedCartValue,
    });
    return this.cart;
  }

  updateProductCountInCart(productId, newCount) {
    const currentCart = this.cart.getValue();
    const updatedCartValue = [...this.cart.getValue().data];
    updatedCartValue.forEach(item => {
      if (item.productId === productId) {
        item.count = newCount;
        item.total = newCount * item.price;
      }
    });
    this.cart.next({
      ...currentCart,
      data: updatedCartValue,
    });
    const total = this.calculateTotal();
    this.cart.next({
      total,
      data: updatedCartValue
    });
    return this.cart;
  }

  createNewsContent(news) {
    const endPoint =  '/api/news/';
    return this._http.put(apiURL + endPoint, news, {headers: headers}).pipe(map((resp: News) => {
      return resp;
    }));
  }

  deleteNewsContent(idNews) {
    const endPoint =  '/api/news/' + idNews;
    return this._http.delete(apiURL + endPoint, {headers: headers});
  }

  updateNewsContent(news) {
    const endPoint =  '/api/news/';
    return this._http.post(apiURL + endPoint, news, {headers: headers});
  }

  uploadContentFile(file: any, containerName: string) {
    const endPoint = '/api/storage';
    const formData = new FormData();
    formData.append('fileObject', file, file.name);
    formData.append('containerName', containerName);
    formData.append('createContainerIfNotExists', 'false');

    return this._http.put(apiURL + endPoint, formData).pipe(map((resp: StorageFile) => {
      return resp;
    }));
  }

  uploadContentBlob(formData: FormData, containerName: string, createContainer: boolean = false, archesType?: string) {
    const endPoint = '/api/storage';
    formData.append('createContainerIfNotExists', `${createContainer}`);
    formData.append('containerName', containerName);
    if (archesType) {
      formData.append('fileParams', archesType);
    }

    return this._http.put(apiURL + endPoint, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getContentFile(containerName, fileName) {
    const endPoint = '/api/storage/';
    const reques = {
      containerName: containerName ? containerName : '',
      fileName: fileName
    };
    return this._http.get(apiURL + endPoint, {params: reques,
      responseType: 'blob',
      observe: 'response',
      reportProgress: true}).pipe(map(resp => {
        return resp.body;
      }));
  }

  deleteContentFile(containerName, fileName) {
    const endPoint = '/api/storage/';
    const reques = {
      containerName: containerName ? containerName : '',
      fileName: fileName
    };
    return this._http.delete(apiURL + endPoint, {headers: headers, params: reques});
  }

  getPopupsForUser(id) {
    const endPoint = `/api/news/popups/${id}`;

    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  markAsRead(idNews: string, idAdmin: number) {
    const endPoint = `/api/news/popups/markasread/${idNews}/${idAdmin}`;
    return this._http.put(`${apiURL}${endPoint}`, null, {headers: headers});
  }

  getSupportClinicContent() {
    const endPoint = '/api/support/clinic';
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  deleteClinicSupport(idClinicSupport: any) {
    const endPoint = `/api/support/clinic/${idClinicSupport}`;
    return this._http.delete(apiURL + endPoint, {headers: headers});
  }

  getClinicContentById(idClinicSupport: any) {
    const endPoint = `/api/support/clinic/${idClinicSupport}`;
    return this._http.get(apiURL + endPoint, {headers: headers});
  }

  createClinicContent(request) {
    const endPoint = `/api/support/clinic/`;
    return this._http.put(apiURL + endPoint, request, {headers: headers});
  }

  updateClinicContent(request) {
    const endPoint = `/api/support/clinic/`;
    return this._http.post(apiURL + endPoint, request, {headers: headers});
  }

  getContentLink(containerName, fileName) {
    const endPoint = '/api/storage/url';
    const reques = {
      containerName: containerName ? containerName : '',
      fileName: fileName
    };
    return this._http.get(apiURL + endPoint, {headers: headers, params: reques});
  }

  getWebViewerUrl(treatmentCode, idTreatment) {
    const endPoint = `/api/storage/getwebviewerurl/${treatmentCode}/${idTreatment}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  getWebViewerUrlFromFile(treatmentCode, idTreatment, videoFile) {
    const endPoint = `/api/storage/getwebviewerurlfromfile/${treatmentCode}/${idTreatment}/${videoFile}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  getWebViewerFiles(treatmentCode) {
    const endPoint = `/api/storage/getwebviewerfiles/${treatmentCode}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  getAliases(arcadId) {
    const endPoint = `/api/public/GetOrderPublicSetups?publicOrderId=${arcadId}`;
    return this._http.get(`${arcadLab}${endPoint}`, {headers: headers});
  }

  verifyWebViewerUrl(treatmentCode) {
    const endPoint = `/api/storage/verifywebviewer/${treatmentCode}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  buy(request) {
    const endPoint = `/api/shopping/buy`;
    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  setContentHolderCountValue(container: string, value: number) {
    const currentUser = this._userService.readUserFromLocalStorage();
    currentUser[container] = value;
    this._userService.setUserToLocalStorage(currentUser);
  }

  getContentHolderCountValue(container: string) {
    const currentUser = this._userService.readUserFromLocalStorage();

    if (!currentUser) {
      return;
    }

    if (!currentUser[container]) {
      return 0;
    }

    if (currentUser[container] !== undefined) {
      return currentUser[container];
    }

    return 0;
  }
}
