import { HttpClient } from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from '../../modules/security/models/user.model';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  @Output() doctorsLoaded = new EventEmitter();
  @Output() treatmentsLoaded = new EventEmitter();

  constructor(
    private _http: HttpClient
  ) { }

  public doctorsIsLoaded() {
    this.doctorsLoaded.emit(true);
  }

  public treatmentIsLoaded() {
    this.treatmentsLoaded.emit(true);
  }

  public getDoctors() {
      const endPoint = '/api/admin/byrole/DO';
      return this._http.get(apiURL + endPoint, {headers: headers}).pipe(map((resp: User[]) => {
        return resp;
      }));
  }

  public createTreatment(request) {
    const endPoint = '/api/treatment';
    return this._http.put(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  public getTreatment(id) {
    const endPoint = `/api/treatment/${id}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  public getDoctor(id) {
    const endPoint = `/api/admin/${id}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  public getDoctorLite(id) {
    const endPoint = `/api/Admin/Lite/${id}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  public updatePatientData(request: any) {
    const endPoint = '/api/treatment/updatepatientdata';
    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  public saveVideoRequested(idTreatment, videoRequestedValue: any) {
    const endPoint = `/api/treatment/UpdateVideoRequested/${idTreatment}/${videoRequestedValue}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  public saveTreatment(request) {
    const endPoint = '/api/treatment';
    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  public gotoNextPhaseRevision(request) {
    const endPoint = '/api/treatmentplan/gotonext';
    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  public updateDoctorNotes(request) {
    const endPoint = '/api/admin/doctor/updatedoctorinternalnotes';
    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  public getServiceSheetFile(idTreatment, language, idAdmin) {
    const endPoint = `/api/TreatmentPlan/ServiceSheet/${idTreatment}/${language}/${idAdmin}`;
    return this._http.get<Blob>(`${apiURL}${endPoint}`, {observe: 'response', responseType: 'blob' as 'json'});
  }

  public changeStatus(reason: string, treatmentId: any, endpoint: string, request?: any) {
    const endPoint = `/api/treatment/changestatus/${endpoint}`;

    request = request ? request : {
      idTreatment: +treatmentId,
      reason: reason
    };

    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  public getTreatmentPlanPdf(idTreatment: any, language: string) {
    const endPoint = `/api/TreatmentPlan/Pdf/${idTreatment}/${language}`;
    return this._http.get<Blob>(`${apiURL}${endPoint}`, {observe: 'response', responseType: 'blob' as 'json'});
  }

  public getWorkSheetPdf(idTreatment: any, language: string) {
    const endPoint = `/api/TreatmentPlan/WorkSheetPdf/${idTreatment}/${language}`;
    return this._http.get<Blob>(`${apiURL}${endPoint}`, {observe: 'response', responseType: 'blob' as 'json'});
  }

  public deleteTreatment(idTreatment: any) {
    const endPoint = '/api/treatment';
    return this._http.delete(`${apiURL}${endPoint}/${idTreatment}`, {headers: headers});
  }

  public updateWaitingForSTL(value: boolean, idTreatment: any) {
    const endPoint = `/api/treatment/updatewaitingforstl/${value}/${idTreatment}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  updateTreatmentPlanLevel(idTreatment: any, value: any) {
    const endPoint = `/api/treatmentplan/updatelevel/${idTreatment}/${value}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  updateTreatmentPlanMandatoryPhase(data: any) {
    const endPoint = `/api/treatmentplan/updateTreatmentPlanMandatoryPhase`;
    return this._http.post(`${apiURL}${endPoint}`, data, {headers: headers});
  }

  updateTreatmentPlanFieldBool(request: any) {
    const endPoint = `/api/treatmentplan/updatetreatmentplanfield`;
    return this._http.post(`${apiURL}${endPoint}`, request, {headers: headers});
  }

  migrateFromQuote(idQuote: any) {
    const endPoint = `/api/treatment/migratefromquote/${idQuote}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

  deleteQuote(idQuote: any) {
    const endPoint = `/api/quote/${idQuote}`;
    return this._http.delete(`${apiURL}${endPoint}`, {headers: headers});
  }

  confirmQuote(idQuote: any) {
    const endPoint = `/api/quote/confirmquote/${idQuote}`;
    return this._http.get(`${apiURL}${endPoint}`, {headers: headers});
  }

}
